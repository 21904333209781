.swal-button {
  padding: 7px 19px;
  border-radius: 2px;
  background-color: #5c7ae5;
  font-size: 12px;
  /* border: 1px solid #3e549a; */
  /* text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3); */

  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  height: 40px;
}
.swal-button:hover {
  background-color: #32295e !important;
}
.swal-button--cancel {
  padding: 7px 19px;
  border-radius: 2px;
  background-color: #6e7f8c;
  font-size: 12px;
  color: #ffffff;
  /* border: 1px solid #3e549a; */
  /* text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3); */

  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  height: 40px;
}
.swal-button--ok {
  padding: 7px 19px;
  border-radius: 2px;
  background-color: #5c7ae5;
  font-size: 12px;
  /* border: 1px solid #3e549a; */
  /* text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3); */

  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  height: 40px;
}
.swal-button--ok:hover {
  background-color: #32295e !important;
}
.swal-button--cancel:hover {
  background-color: #32295e !important;
}
