/* .contentmap {
   height: 100%; 
  height: 600px;
  width: 100%;
  z-index: 0;
} */
.splitter-layout {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.leaflet-container {
  width: 100%;
  /* height: 100%; */
  height: 100vh;
  z-index: 0;
}
/* .markercluster-map {
  height: 90vh;
}
.content {
  padding: 0px;
}
.content-nomap {
  padding: 25px;
} */
